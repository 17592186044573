"use client";
import { Button, Col, Container, Row } from "react-bootstrap";
import styles from "./styles.module.css";
import Link from "next/link";
import Footer from "@components/Footer";
import Image from "next/image";

const Home = (props) => {
  return (
    <section>
      <div className={`${styles.heroSection} pb-5`}>
        <Container>
          <Row>
            <Col className={`text-center`}>
              <Link href={"/"} className={`navbar-brand`}>
                <div>
                  <h1 className={`${styles.heading} mb-0`}>MIND BALANCE</h1>
                  <p className={`${styles.headingText} text-center`}>
                    “Your sanctuary for self-discovery and growth.”
                  </p>
                </div>
              </Link>
            </Col>
          </Row>

          <Row className="d-flex justify-content-center mt-1">
            {props.accessToken && props.user ? (
              <>
                <Col md={3}>
                  <Link
                    href={
                      props.user.role === "admin"
                        ? "/admin/dashboard"
                        : props.user.role === "professional"
                        ? "/professional/dashboard"
                        : "/dashboard"
                    }
                    className={`py-2 w-100 btn btn-primary mt-2`}
                  >
                    GO TO MY DASHBOARD
                  </Link>
                </Col>
              </>
            ) : (
              <>
                <Col xs={12} sm={8} md={5} lg={4} xl={3}>
                  <Link
                    href="/signup"
                    className={`py-2 w-100 btn btn-primary mt-2`}
                  >
                    JOIN FOR FREE!
                  </Link>
                </Col>
                <Col xs={12} sm={8} md={5} lg={4} xl={3}>
                  <Link
                    href="/login"
                    className={`py-2 w-100 btn btn-primary mt-2`}
                  >
                    I ALREADY HAVE AN ACCOUNT
                  </Link>
                </Col>
              </>
            )}
          </Row>

          <Row className="mt-4">
            <Col sm={12} className="text-center fs-6 text-light-blue fw-medium">
              New Members Today: {props.data.todayNewUsers}
              {/* <span className="ps-lg-5">Total Members: {counts.total}</span> */}
            </Col>
          </Row>
        </Container>
      </div>

      <Container>
        <Row className="pb-5">
          <Col xs={12} md={6} lg={4} className="text-center">
            <h2 className="fw-semibold mianTextColor">Proven results</h2>
            <p>
              Mind Balance is a fun and easy way to unleash your full potential!
              Improve your mindset with great videos, tasks, recommended books
              and movies and more, as you earn gems, stars, avatars and unlock
              new levels of mental mastery!
            </p>
          </Col>
          <Col xs={12} md={6} lg={4} className="text-center">
            <h2 className="fw-semibold mianTextColor">Amazing reports</h2>
            <p>
              Track your results with graphical reports! Discover not only your
              strengths and weaknesses, but watch your progression forward as
              your scores on personality traits continue to improve!
            </p>
          </Col>
          <Col xs={12} md={6} lg={4} className="text-center">
            <h2 className="fw-semibold mianTextColor">Personalized growth</h2>
            <p>
              Start with a self-assessment test and discover weaknesses in your
              personality or jump right into watching specific videos in areas
              you know you need help!
            </p>
          </Col>
          <Col xs={12} md={6} lg={4} className="text-center">
            <h2 className="fw-semibold mianTextColor">Stay motivated</h2>
            <p>
              Progress at your own speed 24/7 in your own or create a support
              group of your friends and/or family for encouragement and
              motivation.
            </p>
          </Col>
          <Col xs={12} md={6} lg={4} className="text-center">
            <h2 className="fw-semibold mianTextColor">Integrated journal</h2>
            <p>
              Monitor your goals, personality traits your working on, strategies
              and tasks your employing, challenges you overcome, positive
              feedback your getting and more!
            </p>
          </Col>
          <Col xs={12} md={6} lg={4} className="text-center">
            <h2 className="fw-semibold mianTextColor">Massive video library</h2>
            <p>
              hundreds of FREE videos tailored to helping you become the best
              version of yourself and living the life you dream of!
            </p>
          </Col>
        </Row>

        <Row className="text-center pb-5">
          <Col md={12} className={`${styles.intro}`}>
            <h3 className="fw-semibold mianTextColor">
              Progress 24/7
              <br />
              from anywhere
            </h3>
          </Col>
          <Col md={12} className="mt-3">
            <a
              href="https://apps.apple.com/app/mind-balance/id6502398464"
              target="_blank"
              rel="noopener noreferrer"
              variant="dark"
              className="p-0 m-0 me-2"
              aria-label="Download the Apple Store app from the App Store"
            >
              <Image
                src="/app_store_apple.png"
                width={160}
                height={50}
                alt="Picture of the author"
              />
            </a>

            <a
              href="https://play.google.com/store/apps/details?id=net.mind_balance&hl=en"
              target="_blank"
              rel="noopener noreferrer"
              variant="dark"
              className="p-0 m-0 me-2"
              aria-label="Download the Apple Store app from the App Store"
            >
              <Image
                src="/play-store_google.png"
                width={160}
                height={50}
                alt="Picture of the author"
              />
            </a>
          </Col>
        </Row>
      </Container>

      <Footer />
    </section>
  );
};

export default Home;
